import React, { ButtonHTMLAttributes, FC } from 'react'
import Loader from './Loader'

const Button: FC<Props> = ({
  children,
  isLoading,
  Icon,
  disabled,
  variant = 'primary',
  iconPosition = 'back',
  ...props
}) => {
  return (
    <button
      {...props}
      disabled={disabled || isLoading}
      className={`shadow-lg flex items-center  justify-center px-4 py-3 text-base font-medium ${
        disabled
          ? 'bg-gray-400 cursor-not-allowed text-white'
          : variant === 'primary'
          ? 'bg-primary-500 hover:bg-primary-700 border-transparent text-white'
          : 'bg-white hover:bg-gray-100 border-primary-500 text-primary-500'
      }  border  rounded-md md:py-4 md:text-lg md:px-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ${
        props?.className
      }`}>
      <div
        className={isLoading ? 'hidden' : 'flex items-center justify-center'}>
        {Icon && iconPosition === 'front' && <div className='mr-2'>{Icon}</div>}
        {children}
        {Icon && iconPosition === 'back' && <div className='ml-2'>{Icon}</div>}
      </div>

      <Loader
        className={`${isLoading ? 'block' : 'hidden'} ${
          variant === 'primary' ? '' : '!text-primary-500'
        }`}
      />
    </button>
  )
}

export default Button

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
  Icon?: any
  variant?: 'primary' | 'secondary'
  iconPosition?: 'front' | 'back'
}
