import { Dialog } from '@headlessui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FC, ReactNode } from 'react'

const Modal: FC<Props> = ({
  onClose,
  title,
  cancelText,
  children,
  pure = false,
  button,
  widthClassName = 'sm:max-w-lg sm:w-full',
}) => {
  const { t } = useTranslation('common')

  return (
    <Dialog open onClose={() => onClose ?? {}}>
      <div className='fixed inset-0 z-50'>
        <div className='flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
          <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />

          <span
            className='hidden sm:inline-block sm:h-screen sm:align-middle'
            aria-hidden='true'>
            &#8203;
          </span>

          <div
            className={`inline-block transform overflow-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all  sm:p-6  sm:align-middle ${widthClassName}`}
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'>
            {onClose && (
              <div className='absolute top-0 right-0 hidden pt-4 pr-4 sm:block'>
                <button
                  onClick={onClose}
                  type='button'
                  className='text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'>
                  <span className='sr-only'>Close</span>
                  <svg
                    className='w-6 h-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>
            )}
            <div className='flex flex-col text-black'>
              <Dialog.Title
                className={`${pure ? 'my-0' : 'my-6'} text-2xl font-bold`}>
                {title}
              </Dialog.Title>
              {!pure && <hr className='w-full' />}
              <div
                className='flex flex-col w-full p-2 my-4 overflow-auto'
                style={{
                  maxHeight: button
                    ? 'calc(100vh - 300px)'
                    : 'calc(100vh - 200px)',
                }}>
                {children}
              </div>
              {button}
              {onClose && (
                <>
                  {!pure && <hr className='w-full' />}
                  <button
                    type='button'
                    className={`p-4 ${
                      !pure ? 'mt-4' : ''
                    } rounded-lg uppercase text-gray-400 hover:bg-gray-200 hover:text-gray-700`}
                    onClick={onClose}>
                    {cancelText ?? t('closeWindow')}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default Modal

interface Props {
  onClose?: () => void
  cancelText?: string
  title: string
  widthClassName?: string
  pure?: boolean
  button?: ReactNode
}
