import useTranslation from 'next-translate/useTranslation'
import React, { FC, useState } from 'react'
import Button from './Button'
import { COOKIE_CONSENT_LEVEL } from './CookieBanner'
import { baseUrl } from './Meta'
import Modal from './Modal'

const CookieModal: FC<Props> = ({ onClose, onSave }) => {
  const { t } = useTranslation('common')
  const [isAllowingAll, setIsAllowingAll] = useState(false)

  const cookies = [
    {
      name: 'cookie_consent',
      domain: baseUrl,
      expirationDate: t('cookieConsentExpiration'),
      description: t('cookieConsentDescription'),
      type: 'Permanent',
    },
    {
      name: 'bookingSession',
      domain: baseUrl,
      expirationDate: 'Session',
      description: t('bookingSessionDescription'),
      type: 'Session',
    },
    {
      name: '__stripe_mid',
      domain: baseUrl,
      expirationDate: t('stripeMidExpiration'),
      description: t('stripeMidDescription'),
      type: 'Permanent',
    },
    {
      name: '__stripe_sid',
      domain: baseUrl,
      expirationDate: t('stripeSidExpiration'),
      description: t('stripeSidDescription'),
      type: 'Permanent',
    },
  ]

  const advertisementCookies = [
    {
      name: '_ga',
      domain: '.coronafreepass.de',
      expirationDate: t('gaExpiration'),
      description: t('gaDescription'),
      type: 'Permanent',
    },
    {
      name: '_gat_UA-186583160-1',
      domain: '.coronafreepass.de',
      expirationDate: t('gatExpiration'),
      description: t('gaDescription'),
      type: 'Permanent',
    },
    {
      name: '_gid',
      domain: '.coronafreepass.de',
      expirationDate: t('gidExpiration'),
      description: t('gaDescription'),
      type: 'Permanent',
    },
  ]

  return (
    <Modal
      widthClassName='max-w-7xl'
      onClose={onClose}
      title={t('dataProtectionSettings')}>
      <p className='my-4 text-xl font-semibold'>{t('whatAreCookies')}</p>
      <p>{t('whatAreCookiesDescription')}</p>
      <p className='my-4 text-xl font-semibold'>
        {t('requiredCookies')}{' '}
        <input
          type='checkbox'
          checked
          className='ml-2 text-gray-400 transform scale-150'
          disabled
        />
      </p>
      <p>{t('requiredCookiesDescription')}</p>
      <table className='mt-4 text-sm text-center table-auto basic-table'>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('domain')}</th>
            <th>{t('expirationDate')}</th>
            <th>{t('description')}</th>
            <th>{t('type')}</th>
          </tr>
        </thead>
        <tbody>
          {cookies.map(
            ({ description, domain, expirationDate, name, type }) => (
              <tr key={name}>
                <td>{name}</td>
                <td>{domain}</td>
                <td>{expirationDate}</td>
                <td>{description}</td>
                <td>{type}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
      <p className='my-4 text-xl font-semibold'>
        {t('advertisement')}{' '}
        <input
          className='ml-2 transform scale-150'
          type='checkbox'
          checked={isAllowingAll}
          onChange={() => setIsAllowingAll(!isAllowingAll)}
        />
      </p>
      <p>{t('advertisementDescription')}</p>
      <table className='mt-4 text-sm text-center table-auto basic-table'>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('domain')}</th>
            <th>{t('expirationDate')}</th>
            <th>{t('description')}</th>
            <th>{t('type')}</th>
          </tr>
        </thead>
        <tbody>
          {advertisementCookies.map(
            ({ description, domain, expirationDate, name, type }) => (
              <tr key={name}>
                <td>{name}</td>
                <td>{domain}</td>
                <td>{expirationDate}</td>
                <td>{description}</td>
                <td>{type}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
      <p className='my-4 text-xl font-semibold'>{t('moreInfo')}</p>
      <p>{t('moreInfoDescription')}</p>
      <Button
        onClick={() => {
          onSave(
            isAllowingAll ? COOKIE_CONSENT_LEVEL.ALL : COOKIE_CONSENT_LEVEL.CORE
          )
          onClose()
        }}
        className='my-8'>
        {t('saveSettings')}
      </Button>
    </Modal>
  )
}

export default CookieModal

interface Props {
  onClose: () => void
  onSave: (level: COOKIE_CONSENT_LEVEL) => void
}
