import TagManager from 'react-gtm-module'

export const initGTM = () => {
  if (process.env.NODE_ENV === 'production') {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })
  }
}

export const event = ({
  name,
  values,
}: {
  name: string
  values?: any
  location?: string
}) => {
  try {
    ;(window as any)?.dataLayer?.push({
      event: name,
      ...(values && { values }),
      ...(location && { location }),
    })
  } catch (error) {}
}

export const eCommerceEvent = ({
  id,
  transactionAffiliation,
  tax,
  total,
  products,
}: {
  id: string
  transactionAffiliation: string
  total: number
  tax: number
  products: {
    name: string
    sku: string
    price: number
    quantity: number
  }[]
}) => {
  try {
    ;(window as any)?.dataLayer?.push({
      transactionId: id,
      transactionAffiliation: transactionAffiliation,
      transactionTotal: total,
      transactionTax: tax,
      transactionProducts: products,
    })
  } catch (error) {}
}
