import Cookie from 'js-cookie'
import { initGTM } from 'lib/analytics'
import { isServer } from 'lib/utils'
import useTranslation from 'next-translate/useTranslation'
import React, { FC, useEffect, useState } from 'react'
import Button from './Button'
import CookieModal from './CookieModal'

export enum COOKIE_CONSENT_LEVEL {
  CORE = 1,
  ALL = 2,
}

const CONSENT_COOKIE = 'cookie_consent'

const CookieBanner: FC<Props> = ({}) => {
  const { t } = useTranslation('common')
  const [isSettingCookie, setIsSettingCookie] = useState(false)

  const [consentCookie, setConsentCookie] = useState(
    Number(Cookie.get(CONSENT_COOKIE))
  )

  const handleAccept = (level: COOKIE_CONSENT_LEVEL) => {
    Cookie.set(CONSENT_COOKIE, String(level), {
      expires: 365,
      sameSite: 'strict',
    })
    setConsentCookie(level)
  }

  useEffect(() => {
    if (consentCookie === COOKIE_CONSENT_LEVEL.ALL) {
      initGTM()
    }
  }, [consentCookie])

  if (consentCookie || isServer) {
    return null
  }

  return (
    <div className='fixed bottom-0 right-0 z-50 w-screen p-6 bg-white border border-gray-300 rounded shadow-lg no-print lg:w-1/2 xl:w-1/3 lg:bottom-5 lg:right-5'>
      <div className='flex items-center'>
        <img src='/logo.svg' height={50} width={50} alt='Icon' />
        <span className='ml-4 text-4xl font-bold'> {t('dataProtection')}</span>
      </div>
      <p className='my-4'>{t('cookieBannerDescription')}</p>
      <div className='flex justify-between'>
        <Button
          onClick={() => handleAccept(COOKIE_CONSENT_LEVEL.ALL)}
          className='mr-4'>
          {t('accept')}
        </Button>
        <Button onClick={() => setIsSettingCookie(true)} variant='secondary'>
          {t('learnMore')}
        </Button>
      </div>
      {isSettingCookie && (
        <CookieModal
          onSave={handleAccept}
          onClose={() => setIsSettingCookie(false)}
        />
      )}
    </div>
  )
}

export default CookieBanner

interface Props {}
